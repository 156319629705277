<template>
  <div>
    2323
    <div id="my-lucky"></div>
  </div>
</template>

<script>
import { SlotMachine } from "lucky-canvas";

console.info("SlotMachine", SlotMachine);

export default {
  name: "testPage",
  data() {
    return {};
  },
  created() {},
  mounted() {
    const myLucky = new SlotMachine("#my-lucky", {
      width: "240px",
      height: "180px",
      blocks: [
        { padding: "10px", background: "#869cfa" },
        { padding: "10px", background: "#e9e8fe" },
      ],
      slots: [
        { order: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9], direction: 1 },
        // { order: [1, 2, 3, 4, 5, 6, 7, 8, 9, 0], direction: -1 },
        // { order: [2, 3, 4, 5, 6, 7, 8, 9, 0, 1], direction: 1 },
      ],
      prizes: [
        { fonts: [{ text: "0", top: "15%" }] },
        { fonts: [{ text: "1", top: "15%" }] },
        { fonts: [{ text: "2", top: "15%" }] },
        { fonts: [{ text: "3", top: "15%" }] },
        { fonts: [{ text: "4", top: "15%" }] },
        { fonts: [{ text: "5", top: "15%" }] },
        { fonts: [{ text: "6", top: "15%" }] },
        { fonts: [{ text: "7", top: "15%" }] },
        { fonts: [{ text: "8", top: "15%" }] },
        { fonts: [{ text: "9", top: "15%" }] },
      ],
      defaultStyle: {
        borderRadius: Infinity,
        background: "#bac5ee",
        fontSize: "32px",
        fontColor: "#333",
      },
      defaultConfig: {
        rowSpacing: "20px",
        colSpacing: "10px",
      },
      end(prize) {
        // console.log(prize)
      },
    });
    // 开始游戏
    const playGame = () => {
      myLucky.play();
    //   setTimeout(() => {
    //     // 假设 4 种结果
    //     const res = [
    //       [9, 9, 6],
    //       [0, 0, 7],
    //       [6, 6, 6],
    //       [8, 8, 8],
    //     ];
    //     // 随机取一组数据
    //     const index = res[(Math.random() * 4) >> 0];
    //     // 调用 stop 方法停止游戏
    //     myLucky.stop(index);
    //   }, 500);
    };
    // 循环演示
    playGame();
    // setInterval(() => {
    //   playGame();
    // }, 6000);
  },
  methods: {},
};
</script>

<style lang='less' scoped>
</style>
